import axios from '@/services/axios-auth'
import router from '@/router'

const baseUrl = process.env.VUE_APP_API_URL

const state = {
  referrals: [],
  currentreferral: {}
}

const getters = {
  referrals: state => state.referrals,
  currentreferral: state => state.currentreferral
}

const actions = {
  getReferrals({commit}, listParams) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}referral/list/?limit=${listParams.limit}&offset=${listParams.offset}`)
    .then(response => {
      resolve(response)
      commit('setReferrals', response.data.results)
    })
    .catch(error => {
      reject(error)
    }))
  },
  getReferral({commit}, {id}) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}referral/${id}/detail/`)
    .then(response => {
      resolve(response)
      commit('setCurrentReferral', response)
    })
    .catch(error => {
      reject(error)
    }))
  },
  createReferral({commit}, createParams) {
    return new Promise((resolve, reject) => axios.post(`${baseUrl}referral/create/`, createParams)
    .then(response => {
      console.log('add referral', response)
      commit('addReferral', response.data)
      resolve(response)
      router.push({ name: 'referrallist' })
    })
    .catch(error => {
      reject(error)
    }))
  },
  updateReferral({commit}, updateParams) {
    console.log('updateParams', updateParams)
    return new Promise((resolve, reject) => axios.put(`${baseUrl}referral/${updateParams.id}/edit/`, updateParams)
    .then(response => {
      commit('updateReferral', response.data)
      resolve(response)
      router.push({ name: 'referrallist' })
    })
    .catch(error => {
      reject(error)
    }))
  },
  deleteReferral({commit}, {id}) {
    console.log('id', id)
    return new Promise((resolve, reject) => axios.delete(`${baseUrl}referral/${id}/delete/`)
    .then(response => {
      commit('deletereferral', response.data)
      resolve(response)
      router.push({ name: 'referrallist' })
    })
    .catch(error => {
      reject(error)
    }))
  }
}

const mutations = {
  setReferrals(state, referrals) {
    state.referrals = referrals
  },
  setCurrentReferral(state, referral) {
    state.currentreferral = referral
  },
  addReferrals(state, referral) {
    state.referrals.push(referral)
  },
  updateReferral(state, referral) {
    state.referrals[state.referrals.findIndex(el => el.id === referral.id)].first_name = referral.first_name;
    state.referrals[state.referrals.findIndex(el => el.id === referral.id)].last_name = referral.last_name;
    state.referrals[state.referrals.findIndex(el => el.id === referral.id)].email = referral.email;
  },
  deleteReferral(state, item) {
    let index = state.referrals.findIndex(referral => referral === item.id);
    state.referrals.splice(index, 1);
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
