<template>
  <div id="inspire">
    <Sidebar :drawer="drawer" @toggleDrawer="toggleDrawer"/>
    <v-toolbar
      class="hidden-md-and-up mobile-toolbar"
      fixed
      dark
    >
      <div @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </div>
      <v-toolbar-title class="pl-4">Black Business Alliance</v-toolbar-title>
    </v-toolbar>
    <router-view></router-view>
  </div>
</template>
<script>

import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'Dashboard',
  data () {
    return {
      drawer: null,
    }
  },
  components: {
    Sidebar
  },
  mounted() {

  },
  methods: {
    toggleDrawer(data) {
      this.drawer = data
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
