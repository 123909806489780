//import axios from '@/services/axios-auth'
import axios from 'axios'
import router from '@/router'
//import Cookies from 'js-cookie';

const baseUrl = process.env.VUE_APP_API_URL
const state = {
  user: null
}

const getters = {
  user: state => state.user
}

const actions = {
  login({commit}, {email, password}) {
    return new Promise((resolve, reject) => axios.post(`${baseUrl}auth/login/`, {
      email: email,
      password: password
    })
    .then(response => {
      console.log('response', response)
      localStorage.setItem('login-token', response.data.token.access);
      resolve(response)
      commit('setUser',response.data.user)
    })
    .catch(error => {
      console.log('errorStore', error)
      reject(error)
    }))
  },
  logout({commit}) {
    localStorage.removeItem('login-token');
    commit('removeUser')
    router.push({ name: 'login' })
  }
}

const mutations = {
    setUser(state, userData) {
      console.log('userData', userData)
      state.user = userData
    },
    removeUser(state) {
      state.user = null
      localStorage.removeItem('login-token');
    }
}

export default {
  state,
  getters,
  actions,
  mutations
}
