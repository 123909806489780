import axios from '@/services/axios-auth'
//import router from '@/router'

const baseUrl = process.env.VUE_APP_API_URL

const state = {
  industries: null,
  businesstypes: null
}

const getters = {
  industries: state => state.industries,
  businesstypes: state => state.businesstypes
}

const actions = {
  getIndustries({commit}, listParams) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}industry/list/?${new URLSearchParams(listParams).toString()}`)
    .then(response => {
      resolve(response)
      commit('setIndustries', response.data.results)
    })
    .catch(error => {
      reject(error)
    }))
  },
  getBusinessTypes({commit}, listParams) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}business-type/?${new URLSearchParams(listParams).toString()}`)
    .then(response => {
      resolve(response)
      commit('setBusinessTypes', response.data.results)
    })
    .catch(error => {
      reject(error)
    }))
  }
}
const mutations = {
  setIndustries(state, industries) {
    state.industries = industries
  },
  setBusinessTypes(state, types) {
    state.businesstypes = types
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
