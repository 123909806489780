import axios from '@/services/axios-auth'
//import router from '@/router'

const baseUrl = process.env.VUE_APP_API_URL

const state = {
  contacts: [],
  currentcontact: {},
  programs: [],
  reasons: [],
  sources: [],
  membership: []
}

const getters = {
  contacts: state => state.contacts,
  currentcontact: state => state.currentcontact
}

const actions = {
  getContacts({commit}, listParams) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}contact/list/?search=${listParams.search}&limit=${listParams.limit}&offset=${listParams.offset}`)
    .then(response => {
      resolve(response)
      commit('setContacts', response.data.results)
    })
    .catch(error => {
      reject(error)
    }))
  },
  getContact({commit}, {id}) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}contact/${id}/detail/`)
    .then(response => {
      resolve(response)
      commit('setCurrentContact', response)
    })
    .catch(error => {
      reject(error)
    }))
  },
  createContact({commit}, createParams) {
    console.log('createParams', createParams)
    return new Promise((resolve, reject) => axios.post(`${baseUrl}contact/create/`, createParams)
    .then(response => {
      commit('addContacts', response.data)
      resolve(response)
      //router.push({ name: 'contactslist' })
    })
    .catch(error => {
      reject(error)
    }))
  },
  updateContact({commit}, updateParams) {
    console.log('updateParams', updateParams)
    return new Promise((resolve, reject) => axios.put(`${baseUrl}contact/${updateParams.id}/edit/`, updateParams)
    .then(response => {
      commit('updateContact', response.data)
      resolve(response)
      //router.push({ name: 'contactslist' })
    })
    .catch(error => {
      reject(error)
    }))
  },
  deleteContact({commit}, {id}) {
    console.log('id', id)
    return new Promise((resolve, reject) => axios.delete(`${baseUrl}contact/${id}/delete/`)
    .then(response => {
      commit('deleteContact', response.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    }))
  },
  getPrograms({commit}, listParams) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}program/list/?${new URLSearchParams(listParams).toString()}`)
    .then(response => {
      resolve(response)
      commit('setPrograms', response.data.results)
    })
    .catch(error => {
      reject(error)
    }))
  },
  getReasons({commit}, listParams) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}reason-for-inquiry/list/?${new URLSearchParams(listParams).toString()}`)
    .then(response => {
      resolve(response)
      commit('setReasons', response.data.results)
    })
    .catch(error => {
      reject(error)
    }))
  },
  getContactSource({commit}) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}contact-source/list/`)
    .then(response => {
      resolve(response)
      commit('setContactSource', response.data.results)
    })
    .catch(error => {
      reject(error)
    }))
  },
  getMembership({commit}) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}membership-type/list/`)
    .then(response => {
      resolve(response)
      commit('setMembership', response.data.results)
    })
    .catch(error => {
      reject(error)
    }))
  }
}

const mutations = {
  setContacts(state, contacts) {
    state.contacts = contacts
  },
  setPrograms(state, programs) {
    state.programs = programs
  },
  setReasons(state, reasons) {
    state.reasons = reasons
  },
  setContactSource(state, sources) {
    state.sources = sources
  },
  setMembership(state, types) {
    state.membership = types
  },
  setCurrentContact(state, contact) {
    state.currentcontact = contact
  },
  addContacts(state, contact) {
    state.contacts.push(contact)
  },
  updateContact(state, contact) {
    state.contacts[state.contacts.findIndex(el => el.id === contact.id)].first_name = contact.first_name;
    state.contacts[state.contacts.findIndex(el => el.id === contact.id)].last_name = contact.last_name;
    state.contacts[state.contacts.findIndex(el => el.id === contact.id)].email = contact.email;
  },
  deleteContact(state, item) {
    let index = state.contacts.findIndex(contact => contact === item.id);
    state.contacts.splice(index, 1);
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
