import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      page: 'dashboard'
    },
    children: [
      {
        path: '/',
        name: 'reports',
        component: () => import('@/views/Reports.vue')
      },
      {
        path: '/staff',
        name: 'staff',
        component: () => import('@/views/Staff.vue'),
        children: [
          {
            path: 'list',
            name: 'stafflist',
            component: () => import('@/components/StaffList.vue'),
            beforeEnter: (to, from, next) => {
              if(store.getters.user.is_superuser) {
                next()
              } else {
                console.log('cant access')
                next('/')
              }
            }
          },
          {
            path: 'add',
            name: 'addstaff',
            component: () => import('@/components/StaffAdd.vue'),
            beforeEnter: (to, from, next) => {
              if(store.getters.user.is_superuser) {
                next()
              } else {
                console.log('cant access')
                next('/')
              }
            }
          },
          {
            path: 'edit/:id',
            name: 'editstaff',
            component: () => import('@/components/StaffEdit.vue'),
            beforeEnter: (to, from, next) => {
              if(store.getters.user.is_superuser) {
                next()
              } else {
                console.log('cant access')
                next('/')
              }
            }
          }
        ]
      },
      {
        path: '/businessdirectory',
        name: 'businessdirectory',
        component: () => import('@/views/Business.vue'),
        children: [
          {
            path: 'list',
            name: 'businessdirectorylist',
            component: () => import('@/components/BusinessList.vue')
          },
          {
            path: 'add',
            name: 'addbusinessdirectory',
            component: () => import('@/components/BusinessAdd.vue')
          },
          {
            path: 'edit/:id',
            name: 'editbusinessdirectory',
            component: () => import('@/components/BusinessEdit.vue')
          },
          {
            path: 'view/:id',
            name: 'viewbusinessdirectory',
            component: () => import('@/components/BusinessView.vue')
          }
        ]
      },
      {
        path: '/contacts',
        name: 'contacts',
        component: () => import('@/views/Contacts.vue'),
        children: [
          {
            path: 'list',
            name: 'contactslist',
            component: () => import('@/components/ContactsList.vue')
          },
          {
            path: 'add',
            name: 'addcontact',
            component: () => import('@/components/ContactAdd.vue')
          },
          {
            path: 'edit/:id',
            name: 'editcontact',
            component: () => import('@/components/ContactEdit.vue')
          },
          {
            path: 'view/:id',
            name: 'viewcontact',
            component: () => import('@/components/ContactView.vue')
          }
        ]
      },
      {
        path: '/referrals',
        name: 'referrals',
        component: () => import('@/views/Referrals.vue'),
        children: [
          {
            path: 'list',
            name: 'referrallist',
            component: () => import('@/components/ReferralsList.vue')
          },
          {
            path: 'add',
            name: 'addreferral',
            component: () => import('@/components/ReferralAdd.vue')
          },
          {
            path: 'edit/:id',
            name: 'editreferral',
            component: () => import('@/components/ReferralEdit.vue')
          },
          {
            path: 'view/:id',
            name: 'viewreferral',
            component: () => import('@/components/ReferralView.vue')
          }
        ]
      }
    ]
  }
]
const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('login-token')) {
      next()
      return
    }
    next('/')
  } else {
    if (localStorage.getItem('login-token')) {
      next('/dashboard')
      return
    }
    next()
  }
})

export default router
