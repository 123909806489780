import axios from '@/services/axios-auth';
//import router from '@/router'

const baseURL = `${process.env.VUE_APP_API_URL}graph`;

const state = {
  /*
  chartData: [
    contacts: {
    ...
    },
    businessContacted: {
    ...
    },
    ...
  ]
  */
  chartData: {},
  currentFilter: '',
  filterOptions: ['weekly', 'monthly', 'yearly'],
};

const getters = {
  chartData: (state) => state.chartData,
  currentFilter: (state) => state.currentFilter,
  filterOptions: (state) => state.filterOptions,
};

const actions = {
  async fetchChartData({ commit }, chartParams) {
    /*
      Sample chartParams
      chartParams = {
        contacts: {
          ...,
          type: 'contacts',
        },
        businessContacted: {
          ...,
          type: 'businessContacted',
        },
        ...
      }
    */
    // const result = await axios.get(`${baseURL}/technicalAssistanceChartData.json`);
    const type = chartParams.type;
    try {
      const {
        date_created_from,
        date_created_to,
        filterBy,
        outreach_specialist_id,
        created_by__id,
      } = chartParams;
      const tz_offset = new Date(date_created_from).getTimezoneOffset();
      let url = `${baseURL}/?date_created_from=${date_created_from}&date_created_to=${date_created_to}&type=${type}&filter_by=${filterBy}&tz_offset=${tz_offset}`;
      if (outreach_specialist_id) {
        url += `&outreach_specialist__id=${outreach_specialist_id}`;
      }
      if (created_by__id) {
        url += `&created_by__id=${created_by__id}`;
      }
      const result = await axios.get(url);
      if (result.status === 200) {
        let data = {};
        data = result.data;
        data.type = type;
        commit('setChartData', data);
      }
    } catch (error) {
      console.log(`Error for ${type}!`, error.message);
    }
  },
  setCurrentFilter({ commit }, filter) {
    commit('setFilter', filter);
  },
};

const mutations = {
  setChartData(state, chartData) {
    /*
      chartData = {
        ...,
        type: 'contacts'
      }
    */
    state.chartData[chartData.type] = chartData;
  },
  setFilter(state, filter) {
    if (state.filterOptions.includes(filter)) {
      state.currentFilter = filter;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
