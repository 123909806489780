import axios from '@/services/axios-auth'

const baseUrl = process.env.VUE_APP_API_URL

const state = {
  reports: [{
    businesscontacted: {},
    business: {},
    contacts: {},
    external: {},
    internal: {}
  }]
}

const getters = {
  reports: state => state.reports
}

const actions = {
  getBCReports({commit}, getParams) {
    console.log('getParams', getParams)
    //const url = getParams.outreach_specialist__id >= 0 ? `${baseUrl}reports/business-contacted/?date_created=${getParams.date_created}&outreach_specialist__id=${getParams.outreach_specialist__id}` : `${baseUrl}reports/business-contacted/?date_created=${getParams.date_created}`
    return new Promise((resolve, reject) => axios.get(`${baseUrl}reports/business-contacted/?${new URLSearchParams(getParams).toString()}`)
    .then(response => {
      resolve(response)
      commit('setReports', { key: 'businesscontacted' , data: response.data} )
    })
    .catch(error => {
      reject(error)
    }))
  },
  getBReports({commit}, getParams) {
    //const url = getParams.id >= 0 ? `${baseUrl}reports/business/?date_created=${getParams.date}&created_by__id=${getParams.id}` : `${baseUrl}reports/business/?date_created=${getParams.date}`
    return new Promise((resolve, reject) => axios.get(`${baseUrl}reports/business/?${new URLSearchParams(getParams).toString()}`)
    .then(response => {
      resolve(response)
      commit('setReports', { key: 'business' , data: response.data } )
    })
    .catch(error => {
      reject(error)
    }))
  },
  getCReports({commit}, getParams) {
    console.log('getParams', getParams)
    //const url = getParams.id < 0 ? `${baseUrl}reports/contacts/?date_created=${getParams.date}` : getParams.admin ? `${baseUrl}reports/contacts/?date_created=${getParams.date}&created_by__id=${getParams.id}` : `${baseUrl}reports/contacts/?date_created=${getParams.date}&outreach_specialist__id=${getParams.id}`
    return new Promise((resolve, reject) => axios.get(`${baseUrl}reports/contacts/?${new URLSearchParams(getParams).toString()}`)
    .then(response => {
      resolve(response)
      commit('setReports', { key: 'contacts' , data: response.data } )
    })
    .catch(error => {
      reject(error)
    }))
  },
  getReferralReports({commit}, getParams) {
    const url = `${baseUrl}reports/referral/${getParams.key}/?${new URLSearchParams(getParams).toString()}`
    return new Promise((resolve, reject) => axios.get(url)
    .then(response => {
      resolve(response)
      commit('setReports', { key: getParams.key , data: response.data } )
    })
    .catch(error => {
      reject(error)
    }))
  },
}

const mutations = {
  setReports(state, report) {
    state.reports[report.key] = report.data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
