<template>
    <v-navigation-drawer
      v-model="showDrawer"
      :permanent="$vuetify.breakpoint.mdAndUp"
      fixed
      dark
      app
      class="sidebar-container"
      :class="{'is-staff' : !currentUser.is_superuser }"
      >
      <v-layout justify-space-between column fill-height>
        <v-list
        dense
        nav
        class="py-0"
        >
        <v-list-item two-line :class="miniVariant && 'px-0'" class="px-0">
          <img class="site-logo mr-2" src="../assets/site_logo.png" alt="Black Business Alliance" />
          <strong>Black Business Alliance</strong>
        </v-list-item>

        <v-divider></v-divider>

        <router-link
            v-for="(item, index) in items"
            :value="item.active"
            :key="`menu-item${index}`"
            :to="{ name: item.path }"
            :class="{'router-link-exact-active router-link-active' : parentRoute == item.parent, 'admin-visible' : item.parent == 'staff' }"
        >
            <v-list-item link>
                <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
        </router-link>
        </v-list>
        <v-btn
          @click="logoutUser()"
           justify-end
           rounded
           outlined
           color="dark"
           class="mb-8 mx-8"
           >Logout</v-btn>
      </v-layout>
    </v-navigation-drawer>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Sidebar',
  props: {
    drawer: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      showDrawer: this.drawer,
      items: [
        { title: 'Reports', icon: 'mdi-chart-bar', path: 'reports', parent: 'reports' },
        { title: 'Business Directory', icon: 'mdi-check-all', path: 'businessdirectorylist', parent: 'businessdirectory' },
        { title: 'Contacts', icon: 'mdi-phone', path: 'contactslist', parent: 'contacts' },
        { title: 'Referrals', icon: 'mdi-arrow-right-circle', path: 'referrallist', parent: 'referral' },
        { title: 'Staff', icon: 'mdi-account-group', path: 'stafflist', parent: 'staff' }
      ],
      color: 'gray',
      colors: [
        'primary',
        'blue',
        'success',
        'red',
        'teal'
      ],
      right: false,
      permanent: true,
      miniVariant: false,
      expandOnHover: false,
      background: false
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    parentRoute() {
      return this.$route.fullPath.split('/')[1]
    },
    currentUser() {
      return this.$store.getters.user
    }
  },
  methods: {
    ...mapActions(['logout']),
    logoutUser() {

      this.logout();
    }

  },
  watch: {
    showDrawer(value) {
      this.$emit('toggleDrawer', value)
    },
    drawer(value) {
      this.showDrawer = value
    }
  }
}
</script>
<style lang="scss" scoped>
.sidebar-container {
    a {
      width: 100%;
      text-decoration: none;
      &.router-link-exact-active .v-list-item:before{
        //opacity:.08 !important;
      }
      &.router-link-exact-active .v-list-item *{
        color: var(--var-primary-color);
      }
    }
    &.is-staff .admin-visible {
      display: none;
    }
    .site-logo {
      height: 35px;
      width: auto;
      margin-right: 4px;
    }
}
.v-list--dense .v-list-item {
  height: 50px;
}
.v-list--dense .v-list-item .v-list-item__icon, .v-list-item--dense .v-list-item__icon {
  margin-top: 12px;
  margin-right: 12px;
}
.v-list--dense .v-list-item .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list-item--dense .v-list-item__title {
  font-size: inherit;
  line-height: inherit;
}
</style>
