<template>
   <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
           <v-overlay :value="overlay">
            <v-progress-circular
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md5 lg4>
                  <v-card
                     border="top"
                     colored-border
                     class="login-card yellow accent-4 elevation-12 px-8 pb-8">
                     <v-card-title>
                        <v-layout class="my-4 d-flex justify-center">
                           <h2 class="secondary--text headline">
                              Login
                           </h2>
                        </v-layout>
                     </v-card-title>
                     <v-card-text class="pt-0">
                       <v-alert
                          :value="errorMessage !== ''"
                          border="top"
                          colored-border
                          type="error"
                          elevation="2"
                           >
                             {{ errorMessage }}
                         </v-alert>
                        <v-form>
                           <v-text-field
                              v-model="email"
                              prepend-icon="person"
                              name="login"
                              label="Email"
                              type="text"
                              hide-details="auto"
                              outlined
                              color="secondary"
                              @keydown.enter="loginAccount"
                           ></v-text-field>
                           <v-text-field
                              v-model="password"
                              id="password"
                              prepend-icon="lock"
                              name="password"
                              label="Password"
                              type="password"
                              hide-details="auto"
                              outlined
                              color="secondary"
                              @keydown.enter="loginAccount"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <!-- <v-divider></v-divider> -->
                     <v-card-actions>
                        <!-- <v-btn
                           to="/dashboard"
                           class="yellow accent-4"
                           x-large
                           block>
                           <v-icon left>lock</v-icon> Login
                        </v-btn> -->
                        <v-btn
                           @click="loginAccount"
                           class="yellow accent-4"
                           x-large
                           block>
                           <v-icon left>lock</v-icon> Login
                        </v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  props: {

  },
  data() {
    return {
      email: null,
      password: null,
      errorMessage: '',
      overlay: false
    }
  },
  methods: {
    ...mapActions(['login']),
    loginAccount() {
      this.overlay = true;
      this.login({email: this.email, password: this.password})
      .then(() => {
        this.$router.push({ name: 'reports' })
        this.overlay = false;
      }, () => {
        this.overlay = false;
        this.errorMessage = 'Email and password mismatch.'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
   @import '../assets/styles/variables.scss';
   .v-application .yellow.accent-4{
      color: #FFF!important;
      background-color: #dbb42b !important;
      border-color: #dbb42b !important;
   }

   .login-card .headline {
      font-weight: bold;
      font-size: 2.2rem!important;
      margin: 1rem 0 1rem;
   }
   .v-card.v-sheet.theme--light {
      background-color: #ffffff !important;
      border-top: 8px solid $primary-color;
   }
   ::v-deep {
      .v-alert__content {
        font-size: small;
      }
      .v-text-field,
      .v-text-field.v-text-field--enclosed:first-child{
         margin-bottom: 12px;
      }
      .v-input__append-outer,
      .v-input__prepend-outer {
         position: absolute;
         top: 0;
         bottom: 0;
         margin: auto!important;
         display: flex;
         align-items: center;
         left: 13px;
         .v-icon {
            color: rgba(0,0,0,.3);
         }
      }
      .v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot {
         margin-left: 35px;
      }
      .theme--light.v-label {
      color: rgba(0,0,0,.3);
      font-weight: bold;
      }
      .v-text-field--outlined legend {
         margin-left: 35px;
      }
      .v-text-field--outlined fieldset{
         border-color: rgba(0,0,0,.3);
      }
   }
</style>
