import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import auth from './modules/auth'
import business from './modules/business'
import contact from './modules/contact'
import industry from './modules/industry'
import referral from './modules/referral'
import reports from './modules/reports'
import staff from './modules/staff'
import charts from './modules/charts'

Vue.use(Vuex)

const vuexStorage = new VuexPersist({
  key: 'sourcelink',
  storage: localStorage
})

const modules = {
  auth,
  staff,
  business,
  contact,
  referral,
  reports,
  industry,
  charts
}

export default new Vuex.Store({
  plugins: [vuexStorage.plugin],
  modules
})
