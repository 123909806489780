import axios from 'axios'
//import env from 'env'
//import Cookie from 'js-cookie'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})
instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (error.response.status === 401 && error.config.baseURL === 'http://localhost:8000/api') {
        //localStorage.removeItem('login-token')
        window.location.reload()
    }

    return Promise.reject(error);
});

instance.interceptors.request.use(config => {
    // Do something before request is sent
    config.headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('login-token')}`
    }
    return config
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default instance
