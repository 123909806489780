import axios from '@/services/axios-auth'
import router from '@/router'

const baseUrl = process.env.VUE_APP_API_URL

const state = {
  specialists: [],
  currentspecialist: {}
}

const getters = {
  specialists: state => state.staff,
  currentspecialist: state => state.currentspecialist
}

const actions = {
  getAllStaff({commit}, listParams) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}staff/list/?${new URLSearchParams(listParams).toString()}`)
    .then(response => {
      resolve(response)
      commit('setStaff', response.data.results)
    })
    .catch(error => {
      reject(error)
    }))
  },
  getStaffs({commit}, listParams) {
    const url = `${baseUrl}staff/list/?${new URLSearchParams(listParams).toString()}`
    return new Promise((resolve, reject) => axios.get(url)
    .then(response => {
      resolve(response)
      commit('setStaff', response.data.results)
    })
    .catch(error => {
      reject(error)
    }))
  },
  searchStaff({commit}, listParams) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}staff/list/?full_name=${listParams.name}&limit=${listParams.limit}&offset=${listParams.offset}`)
    .then(response => {
      //console.log('results', response)
      resolve(response)
      commit('setStaff', response.data.results)
    })
    .catch(error => {
      reject(error)
    }))
  },
  getStaff({commit}, {id}) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}staff/${id}/detail/`)
    .then(response => {
      resolve(response)
      commit('setCurrentStaff', response)
    })
    .catch(error => {
      reject(error)
    }))
  },
  createStaff({commit}, createParams) {
    createParams.username = createParams.email
    createParams.is_staff = true,
    createParams.is_superuser = false
    return new Promise((resolve, reject) => axios.post(`${baseUrl}staff/create/`, createParams)
    .then(response => {
      commit('addStaff', response.data)
      resolve(response)
      router.push({ name: 'stafflist' })
    })
    .catch(error => {
      reject(error)
    }))
  },
  updateStaff({commit}, updateParams) {
    console.log('updateParams', updateParams)

    return new Promise((resolve, reject) => axios.put(`${baseUrl}staff/${updateParams.id}/edit/`, updateParams)
    .then(response => {
      commit('updateStaff', response.data)
      resolve(response)
      //router.push({ name: 'stafflist' })
    })
    .catch(error => {
      reject(error)
    }))
  },
  deleteStaff({commit}, {id}) {
    return new Promise((resolve, reject) => axios.delete(`${baseUrl}staff/${id}/delete/`)
    .then(response => {
      commit('deleteStaff', response.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    }))
  }
}

const mutations = {
  setStaff(state, users) {
    state.staff = users
  },
  setCurrentStaff(state, user) {
    state.currentspecialist = user
  },
  addStaff(state, user) {
    state.staff.push(user)
  },
  updateStaff(state, user) {
    state.staff[state.staff.findIndex(el => el.id === user.id)].first_name = user.first_name;
    state.staff[state.staff.findIndex(el => el.id === user.id)].last_name = user.last_name;
    state.staff[state.staff.findIndex(el => el.id === user.id)].email = user.email;
  },
  deleteStaff(state, user) {
    let index = state.staff.findIndex(staff => staff.id === user.id);
    state.staff.splice(index, 1);
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
