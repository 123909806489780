import axios from '@/services/axios-auth'
//import router from '@/router'

const baseUrl = process.env.VUE_APP_API_URL

const state = {
  businesses: [],
  currentbusiness: {}
}

const getters = {
  businesses: state => state.businesses,
  currentbusiness: state => state.currentbusiness
}

const actions = {
  getBusinesses({commit}, listParams) {
    const url = `${baseUrl}business/list/?${new URLSearchParams(listParams).toString()}`
    return new Promise((resolve, reject) => axios.get(url)
    .then(response => {
      resolve(response)
      commit('setBusinesses', response.data.results)
    })
    .catch(error => {
      reject(error)
    }))
  },
  getBusiness({commit}, {id}) {
    return new Promise((resolve, reject) => axios.get(`${baseUrl}business/${id}/detail/`)
    .then(response => {
      resolve(response)
      commit('setCurrentBusiness', response)
    })
    .catch(error => {
      reject(error)
    }))
  },
  createBusiness({commit}, createParams) {
    return new Promise((resolve, reject) => axios.post(`${baseUrl}business/create/`, createParams)
    .then(response => {
      commit('addBusiness', response.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    }))
  },
  updateBusiness({commit}, updateParams) {
    console.log('updateParams', updateParams)
    return new Promise((resolve, reject) => axios.put(`${baseUrl}business/${updateParams.id}/edit/`, updateParams)
    .then(response => {
      commit('updateBusiness', response.data)
      resolve(response)
      //router.push({ name: 'businessdirectorylist' })
    })
    .catch(error => {
      reject(error)
    }))
  },
  deleteBusiness({commit}, {id}) {
    console.log('id', id)
    return new Promise((resolve, reject) => axios.delete(`${baseUrl}business/${id}/delete/`)
    .then(response => {
      commit('deleteBusiness', response.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    }))
  }
}

const mutations = {
  setBusinesses(state, business) {
    state.businesses = business
  },
  setCurrentBusiness(state, business) {
    state.currentbusiness = business
  },
  addBusiness(state, business) {
    state.businesses.push(business)
  },
  updateBusiness(state, business) {
    state.businesses[state.businesses.findIndex(el => el.id === business.id)].first_name = business.first_name;
    state.businesses[state.businesses.findIndex(el => el.id === business.id)].last_name = business.last_name;
    state.businesses[state.businesses.findIndex(el => el.id === business.id)].email = business.email;
  },
  deleteBusiness(state, item) {
    let index = state.businesses.findIndex(business => business === item.id);
    state.businesses.splice(index, 1);
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
